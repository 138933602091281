import React from "react";

import Layout from "../../../components/base/layout";
import SEO from "../../../components/base/seo";

const VisualLanguagePackage = () => (
  <Layout>
    <SEO title="Visual Language Package Addons" />
    <div className="container">
      <section className="section-checkout-form page-heading">
        <div className="checkout-product-heading">
          <h2>Additional Support Hours | $175 per hour</h2>
          <p>booked in blocks of 3 hours.</p>
        </div>
        <div className="checkout-form-details">
          <div className="checkout-desc">
            <p>
              Purchased in 3-hour blocks. These will rollover if they are unused
              and are always used last within a given month (so as to not eat
              into your support package hours).
            </p>
          </div>
        </div>
      </section>
    </div>
  </Layout>
);

export default VisualLanguagePackage;
